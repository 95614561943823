<template>
  <div>
    <v-spacer></v-spacer>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" placeholder="Search..." color="#c79477"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="purchases" :search="search" :loading="isLoading" item-key="index" class="elevation-1" transition="scale-transition" no-data-text="You have not made any purchases yet, please view our DIY Documents.">
        <template v-slot:item.title="{ item }">
          <h5 class="primary__text">
            {{ item.title }}
          </h5>
        </template>
        <template v-slot:item.status="{ item }">
          <h5 class="primary__text">
            <v-chip class="ma-2 wht" x-small :color="item.status == 'completed' ? 'success' : 'primary'">{{ item.status == "completed" ? "Completed" : "New" }}</v-chip>
          </h5>
        </template>
        <template v-slot:item.order_date="{ item }">
          <h5 color="primary">{{ item.order_date | getFormattedYYYYMMDD }}</h5>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn-toggle shaped>
            <v-btn color="primary" v-if="!item.status || item.status == 'new'" x-small @click="view(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </template>
                <span>Populate Your Document</span>
              </v-tooltip>
            </v-btn>
            <v-btn color="success" v-if="item.status == 'completed'" x-small @click="download(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-folder-download
                  </v-icon>
                </template>
                <span>Download Your Completed Document</span>
              </v-tooltip>
            </v-btn>
            <v-btn color="warning" x-small @click="disclaimer(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>View DIY Document Disclaimer</span>
              </v-tooltip>
            </v-btn>
            <v-btn color="cyan darken-1" x-small @click="$router.push(`/user/purchases/order/${item.order_id}`)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-receipt
                  </v-icon>
                </template>
                <span>Invoice</span>
              </v-tooltip>
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-data-table>
    </v-card>

    <v-btn class="btn__primary-colour mt-3" @click="$router.push('/products')"> View Our DIY Documents</v-btn>

    <!-- Disclaimer Modal -->
    <base-modal :show="showDisclaimer" :width="500">
      <template v-slot:header>
        <h3>Disclaimer</h3>
      </template>
      <template v-slot:body>
        <base-loader :isLoading="disclaimerLoading"></base-loader>
        {{ disclaimerData.disclaimer }}
      </template>
      <template v-slot:footer>
        <v-btn class="btn__primary-colour" @click="showDisclaimer = false">Close</v-btn>
      </template>
    </base-modal>

    <!-- Downloads modal -->
    <base-modal :show="showDownloads">
      <template v-slot:header>
        <h3>Downloads</h3>
      </template>
      <template v-slot:body>
        <h4 style="margin-bottom: 10px" align="center">
          Please find assembled files below:
        </h4>
        <download-links v-if="downloadLinksReady" :links="downloadLinks"></download-links>
      </template>
      <template v-slot:footer>
        <v-btn class="btn__primary-colour" @click="showDownloads = false">Close</v-btn>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import DownloadLinks from "@/components/Shared/DownloadLinks";
export default {
  components: {
    DownloadLinks,
  },
  data() {
    return {
      headers: [
        { text: "Order", value: "order_id", sortable: false, align: "start" },
        { text: "DIY Document", value: "title", sortable: false, align: "left" },
        {
          text: "Purchase Date",
          value: "order_date",
          sortable: true,
          align: "right",
        },
        { text: "Status", value: "status", sortable: true, align: "right" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      search: "",
      skipQuery: true,
      purchases: [],
      isLoading: false,
      showDisclaimer: false,
      showDownloads: false,
      downloadLinks: [],
      downloadLinksReady: false,
      disclaimerData: {},
      disclaimerLoading: false,
      purchasesCount: 0,
    };
  },
  apollo: {
    orders: {
      query: gql`
        query Orders($limit: Int!, $skip: Int!, $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              totalAmount
              products
              createdAt
              payment
              status
            }
            count
          }
        }
      `,
      skip() {
        return this.skipQuery;
      },
      variables() {
        const self = this;
        return {
          limit: 9999,
          skip: 0,
          query: {
            createdBy: self.me.id,
            payment: "complete",
          },
        };
      },
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response) {
        const self = this;
        self.purchases = [];
        self.orders.records.forEach((item) => {
          item.products.forEach((purchase, i) => {
            purchase.order_id = item.id;
            purchase.index = `${item.id}:${purchase.id}:${i}`;
            purchase.order_date = item.createdAt;
            purchase.status = purchase.status == "completed" ? "completed" : "new";
            self.purchases.push(purchase);
          });
        });
        self.purchases = _.orderBy(self.purchases, ["order_date"], ["desc"]);

        self.purchasesCount = self.purchases.length;
      },
    },
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      result() {
        const self = this;
        self.$apollo.queries.orders.skip = false;
        self.$apollo.queries.orders.refetch();
      },
    },
  },
  methods: {
    view(item) {
      this.$router.push(`/user/purchases/survey/${item.order_id}/${item.id}`);
    },
    async disclaimer(item) {
      const self = this;
      self.disclaimerLoading = true;

      // this.disclaimerData = item
      await self.$apollo
        .query({
          query: gql`
            query product($id: ID!) {
              product(id: $id) {
                id
                disclaimer
              }
            }
          `,
          variables: {
            id: item.id,
          },
        })
        .then((response) => {
          if (response?.data?.product) {
            self.disclaimerData = response.data.product;
            self.showDisclaimer = true;
            self.disclaimerLoading = false;
          }
        });
    },
    download(item) {
      this.showDownloads = true;
      this.downloadLinks = [];
      if (Array.isArray(item.download_links)) {
        this.downloadLinks = item.download_links;
      } else {
        this.downloadLinks.push(item.download_links);
      }
      this.downloadLinksReady = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
