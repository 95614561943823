var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-spacer'),_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('v-text-field',{attrs:{"placeholder":"Search...","color":"#c79477"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.purchases,"search":_vm.search,"loading":_vm.isLoading,"item-key":"index","transition":"scale-transition","no-data-text":"You have not made any purchases yet, please view our DIY Documents."},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"primary__text"},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"primary__text"},[_c('v-chip',{staticClass:"ma-2 wht",attrs:{"x-small":"","color":item.status == 'completed' ? 'success' : 'primary'}},[_vm._v(_vm._s(item.status == "completed" ? "Completed" : "New"))])],1)]}},{key:"item.order_date",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("getFormattedYYYYMMDD")(item.order_date)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"shaped":""}},[(!item.status || item.status == 'new')?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.view(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Populate Your Document")])])],1):_vm._e(),(item.status == 'completed')?_c('v-btn',{attrs:{"color":"success","x-small":""},on:{"click":function($event){return _vm.download(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-folder-download ")])]}}],null,true)},[_c('span',[_vm._v("Download Your Completed Document")])])],1):_vm._e(),_c('v-btn',{attrs:{"color":"warning","x-small":""},on:{"click":function($event){return _vm.disclaimer(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("View DIY Document Disclaimer")])])],1),_c('v-btn',{attrs:{"color":"cyan darken-1","x-small":""},on:{"click":function($event){return _vm.$router.push(("/user/purchases/order/" + (item.order_id)))}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-receipt ")])]}}],null,true)},[_c('span',[_vm._v("Invoice")])])],1)],1)]}}])})],1),_c('v-btn',{staticClass:"btn__primary-colour mt-3",on:{"click":function($event){return _vm.$router.push('/products')}}},[_vm._v(" View Our DIY Documents")]),_c('base-modal',{attrs:{"show":_vm.showDisclaimer,"width":500},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Disclaimer")])]},proxy:true},{key:"body",fn:function(){return [_c('base-loader',{attrs:{"isLoading":_vm.disclaimerLoading}}),_vm._v(" "+_vm._s(_vm.disclaimerData.disclaimer)+" ")]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"btn__primary-colour",on:{"click":function($event){_vm.showDisclaimer = false}}},[_vm._v("Close")])]},proxy:true}])}),_c('base-modal',{attrs:{"show":_vm.showDownloads},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Downloads")])]},proxy:true},{key:"body",fn:function(){return [_c('h4',{staticStyle:{"margin-bottom":"10px"},attrs:{"align":"center"}},[_vm._v(" Please find assembled files below: ")]),(_vm.downloadLinksReady)?_c('download-links',{attrs:{"links":_vm.downloadLinks}}):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"btn__primary-colour",on:{"click":function($event){_vm.showDownloads = false}}},[_vm._v("Close")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }